import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  NgModule,
  Provider,
} from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DialogModule } from '@/components/dialog/dialog.module';
import { LoadingFullScreenComponent } from '@/components/loading-full-screen/loading-full-screen.component';
import { ErrorHandler } from '@/http/error.interceptor';
import { JwtInterceptor } from '@/http/jwt.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SnackBarModule } from './components/snack-bar/snack-bar.module';
import { NgxMaskModule } from 'ngx-mask';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SuccessMessageInterceptor } from '@/http/success-message.interceptor';
import { MenuService } from '@/services/menu.service';

const customProviders: Provider[] = [
  { provide: LOCALE_ID, useValue: 'pt-BR' },
  { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
  { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorHandler, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: SuccessMessageInterceptor,
    multi: true,
  },
  { provide: MatDialogRef, useValue: {} },
  { provide: MAT_DIALOG_DATA, useValue: {} },
];

registerLocaleData(localePt);
@NgModule({
  declarations: [AppComponent, LoadingFullScreenComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DialogModule,
    HttpClientModule,
    SnackBarModule,
    CdkStepperModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [...customProviders, MenuService],
  bootstrap: [AppComponent],
})
export class AppModule {}
